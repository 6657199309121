import React, { useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import Bugsnag from "@bugsnag/js";

import DropzoneFile from "../DropzoneFile/index";
import { CustomModal } from "../GoogleMap/components/Modal";
import { ImageCropper } from "../ImageCropper";
import { useDraftImages } from "../../../pages/drafts/hooks/useDraftImages";
import useUploadImagesToS3 from "../../../pages/drafts/hooks/useUploadImagesS3";
import { imageToImageData } from "../../helpers/image-to-image-data";

const ImageUploader = ({ onCropImage, onMount }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const uploadFiles = useUploadImagesToS3();

  const onOpenModal = useCallback(() => setIsOpenModal(true), [setIsOpenModal]);

  const onCloseModal = useCallback(() => {
    setIsOpenModal(false);
    setSelectedImage(null);
  }, [setIsOpenModal, setSelectedImage]);

  const onCloseCropperModal = useCallback(
    () => setSelectedImage(null),
    [setSelectedImage]
  );

  const onSelectedImage = useCallback(
    async (file) => {
      await uploadFiles(file)
        .then(async (response) => {
          const { data } = response;
          const image = data[0];
          const formattedImage = await imageToImageData(
            image.file.link,
            image.file.id,
            image.file.name
          );

          setSelectedImage({
            ...file[0],
            preview: formattedImage.preview,
          });
        })
        .catch((e) => {
          Bugsnag.notify(e, (event) => {
            event.addMetadata("uploadFilesError", { formattedImage, file });
          });
        });
    },
    [setSelectedImage]
  );

  useEffect(() => {
    setIsOpenModal(false);
  }, [selectedImage]);

  useEffect(() => {
    onMount({
      onOpen: onOpenModal,
      onClose: onCloseModal,
    });
  }, [onOpenModal, onCloseModal, onMount]);

  return (
    <>
      <CustomModal
        title="Choose photo of your profile"
        isOpen={isOpenModal && !selectedImage}
        onClose={onCloseModal}
      >
        <DropzoneFile
          onReceiveFile={onSelectedImage}
          onCloseModal={onCloseModal}
        />
      </CustomModal>
      <CustomModal
        title="Crop your profile photo"
        isOpen={!!selectedImage}
        onClose={onCloseCropperModal}
      >
        <ImageCropper
          selectedImage={selectedImage}
          onCropImage={onCropImage}
          onCloseCropper={onCloseCropperModal}
          isRoundedCropper={false}
        />
      </CustomModal>
    </>
  );
};

ImageUploader.propTypes = {
  onMount: PropTypes.func,
  onCropImage: PropTypes.func,
};

export default ImageUploader;
