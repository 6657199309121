import styled from "styled-components";

export const BannerContainer = styled.div`
  width: auto;
  height: auto;
  border-radius: 4px;
  background-color: #eef6f1;
  border: 1px solid #cededb;
  padding: 4px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
`;

export const BannerText = styled.div`
  color: #2f665c;
  text-align: center;
`;
