export const STARTER_SUBSCRIPTION_PRICE = 35;
export const BUSINESS_SUBSCRIPTION_PRICE = 50;
export const UNLIMITED_SUBSCRIPTION_PRICE = 65;
export const TRIAL_SUBSCRIPTION_PRICE = 0;
export const EXPIRED_SUBSCRIPTION_PRICE = 0;
export const BETA_SUBSCRIPTION_PRICE = 0;

export const subscriptionList = [
  {
    type: 2,
    notation: "",
    title: "Starter",
    price: STARTER_SUBSCRIPTION_PRICE.toString(),
    options: [
      "100 Active listings",
      "50 Drafts",
      "Ebay, Poshmark, Etsy, Mercari",
      "No Employee Invites",
    ],
  },
  {
    type: 3,
    notation: "Most Popular",
    title: "Business",
    price: BUSINESS_SUBSCRIPTION_PRICE.toString(),
    options: [
      "500 Active listings",
      "100 Drafts",
      "Ebay, Poshmark, Etsy, Mercari",
      "1 Employee Invite",
    ],
  },
  {
    type: 4,
    notation: "Best Value",
    title: "Unlimited",
    price: UNLIMITED_SUBSCRIPTION_PRICE.toString(),
    options: [
      "Unlimited Active listings",
      "Unlimited Drafts",
      "Ebay, Poshmark, Etsy, Mercari",
      "Unlimited Employee Invites",
    ],
  },
];
