import React from "react";
import { BannerContainer, BannerText } from "./index.styles";

export const CustomBanner = ({text}) => {
  return (
    <BannerContainer>
      <BannerText>
        {text}
      </BannerText>
    </BannerContainer>
  );
};