import React, { useRef } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Tooltip } from "reactstrap";
import { noop } from "lodash";

import Checkbox from "../../../../base/components/Checkbox";
import { MarketplacesMap } from "../../../drafts/constants";
import { useTooltip } from "../../../../base/hooks/useTooltip";
import styles from "./index.module.scss";
import MobileRowItem from "./components/MobileRowItem";
import { COLORS_NAMES } from "../../../../base/constants/draft";

const commonClasses = classNames(
  styles.shippingListCell,
  "d-flex align-items-center fw-bold"
);

const PickingCard = ({
  id,
  title,
  sku,
  color,
  marketplaceType,
  shippingCost,
  onCheckboxClick = noop,
}) => {
  const tooltipTogglerRef = useRef(null);
  const mobileTooltipTogglerRef = useRef(null);

  return (
    <div className={styles.shippingListItem}>
      <div className={classNames("row", styles.desktopContainer)}>
        <div className={classNames(commonClasses, "col-5")}>
          <div className="text-truncate">{title}</div>
        </div>
        <div className={classNames(commonClasses, "col-2")}>{sku || "-"}</div>
        <div
          className={classNames(
            styles.shippingListCell,
            "d-flex align-items-center col-2"
          )}
        >
          {COLORS_NAMES[color] || color || "-"}
        </div>
        <div className={classNames(commonClasses, "col-2")}>
          <div className={styles.chip}>
            {MarketplacesMap?.[marketplaceType]}
          </div>
        </div>
        <div className={classNames(commonClasses, "col-1")}>
          <Checkbox
            className="ml-3"
            ref={tooltipTogglerRef}
            onClick={(event) => onCheckboxClick(event, id, shippingCost)}
          />
          <Tooltip placement="top" {...useTooltip(tooltipTogglerRef)}>
            Mark as picked
          </Tooltip>
        </div>
      </div>
      <div className={styles.mobileContainer}>
        <div className={styles.mobileRowWrapper}>
          <div className={styles.mobileTitle}>{title}</div>
        </div>
        <MobileRowItem label="SKU">{sku || "-"}</MobileRowItem>
        <MobileRowItem label="Color">{COLORS_NAMES[color] || color || "-"}</MobileRowItem>
        <MobileRowItem label="Marketplace">
          <div className={styles.chip}>
            {MarketplacesMap?.[marketplaceType]}
          </div>
        </MobileRowItem>
        <div className={styles.mobileCheckbox}>
          <Checkbox
            ref={mobileTooltipTogglerRef}
            onClick={(event) => onCheckboxClick(event, id, shippingCost)}
          />
          <Tooltip placement="top" {...useTooltip(mobileTooltipTogglerRef)}>
            Mark as picked
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

PickingCard.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  sku: PropTypes.string,
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  marketplaceType: PropTypes.oneOf([1, 2, 3, 4, 5]),
  shippingCost: PropTypes.number,
  onCheckboxClick: PropTypes.func,
};

export default PickingCard;
