import Bugsnag from "@bugsnag/js";

export const imageToImageData = async (
  url: string,
  id: string,
  path: string
) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "anonymous";
    img.src = url;

    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");
      ctx?.drawImage(img, 0, 0);
      const imageData = ctx?.getImageData(0, 0, img.width, img.height);
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error("Invalid image blob"));
          return;
        }

        const formattedBlob = new Blob([blob], { type: "image/png" });
        const preview = URL.createObjectURL(formattedBlob);

        resolve({
          file: {
            path,
          },
          preview,
          id,
        });
      }, "image/png");
    };

    img.onerror = (error) => {
      Bugsnag.notify(new Error(error as string), (event) => {
        event.addMetadata("imageToImageDataError", {
          url,
          id,
          path,
        });
      });

      reject(new Error(`Failed to load image: ${error.toString()}`));
    };
  });
};
