/* eslint-disable react/prop-types */
import React, { useEffect, useCallback } from "react";
import Bugsnag from "@bugsnag/js";

import { ReactComponent as EbayLogo } from "../../../../../../../assets/images/settings/logo ebay.svg";
import { ReactComponent as PoshmarkLogo } from "../../../../../../../assets/images/settings/logo-poshmark.svg";
import { ReactComponent as EtsyLogo } from "../../../../../../../assets/images/settings/logo-etsy.svg";
import { ReactComponent as MercariLogo } from "../../../../../../../assets/images/settings/logo-mercari.svg";
import { ReactComponent as GoogleLogoWhite } from "../../../../../../../assets/images/settings/icon-google(1).svg";
import { useService } from "../../../../../../../base/hooks/useService";
import MarketPlaceService from "../../../../../../../services/MarketplaceService";
import CardContent from "../../../components/CardContent";
import ButtonsContainer from "../../../components/ButtonsContainer";
import { useOpenWindow } from "../../../../../hooks/useOpenWindow";
import StorageService from "../../../../../../../services/StorageService";
import {
  KEY_EBAY_CONNECT,
  KEY_ETSY_CONNECT,
} from "../../../../../../../base/constants/storage";
import { usePoshmarkIsConnect } from "../../../../../hooks/Poshmark/usePoshmarkIsConnect";
import { useMercariIsConnect } from "../../../../../hooks/useMercariIsConnect";
import PoshmarkExtension from "../../../../../../../services/PoshmarkExtension";
import MercariExtension from "../../../../../../../services/MercariExtension";
import { MARKETPLACES_NAMES } from "../../../../../constants";
import MarketplaceConnectedPlaceholder from "../MarketplaceConnectedPlaceholder";
import { useEtsyIsConnect } from "../../../../../hooks/useEtsyIsConnect";
import { useEbayIsConnect } from "../../../../../hooks/useEbayIsConnect";
import InstallExtension from "../../../components/InstallExtension";
import CustomButton from "../../../../../../../base/components/CustomButton";
import { phrases } from "../../../../../../../store/phrases";

export const EBAY_PARAMS = { STATE: "custom-state-value" };

const getDisconnectMessage = (market) =>
  `Do you want to disconnect your ${market} account?`;

export const MARKETPLACE_PLATFORMS = [
  {
    name: "eBay",
    backendKey: "ebayAccount",
    GoogleLogo: GoogleLogoWhite,
    isExtension: true,
    Logo: EbayLogo,
    CardContent: ({ data }) => {
      const { isConnected: isConnectedEbay, isInstalled } = useEbayIsConnect();

      return (
        <>
          {!isInstalled ? (
            <InstallExtension />
          ) : !isConnectedEbay || !data ? (
            <CardContent />
          ) : (
            <MarketplaceConnectedPlaceholder />
          )}
        </>
      );
    },
    MarketplaceButtons: (props) => {
      const { handleUpdateMarketplace, data } = props;

      const storage = useService(StorageService);
      const marketplaceService = useService(MarketPlaceService);

      const { isConnected: isConnectedEbay, isInstalled } = useEbayIsConnect();

      const [connectionInfo, handleOpenWindow] =
        useOpenWindow(KEY_EBAY_CONNECT);

      useEffect(() => {
        if (connectionInfo?.state !== EBAY_PARAMS.STATE) return;
        if (connectionInfo?.code) {
          marketplaceService
            .connectEbayAccount(connectionInfo.code)
            .then(() => handleUpdateMarketplace())
            .catch((e) => Bugsnag.notify(e))
            .finally(() => storage.set(KEY_EBAY_CONNECT, {}));
        }
      }, [
        connectionInfo,
        storage,
        marketplaceService,
        handleUpdateMarketplace,
      ]);

      const handleSignIn = useCallback(() => {
        marketplaceService
          .getEbayAccount()
          .then(({ data: { authUrl } }) => authUrl)
          .then(handleOpenWindow)
          .catch((e) => Bugsnag.notify(e));
      }, [marketplaceService, handleOpenWindow]);

      const handleDisconnect = useCallback(() => {
        marketplaceService
          .disconnectEbayAccount()
          .then(() => handleUpdateMarketplace())
          .catch((e) => Bugsnag.notify(e));
      }, [marketplaceService, handleUpdateMarketplace]);

      return (
        <ButtonsContainer
          {...props}
          data={data && isConnectedEbay}
          handleSignIn={handleSignIn}
          handleDisconnect={handleDisconnect}
          modalText={getDisconnectMessage(MARKETPLACES_NAMES.ebay)}
          isExtension={!isInstalled}
        />
      );
    },
  },
  {
    name: "Poshmark",
    status: "Not connected",
    Logo: PoshmarkLogo,
    GoogleLogo: GoogleLogoWhite,
    backendKey: "poshmarkAccountDto", // <-- this is important
    isExtension: true,
    CardContent: (props) => {
      const { isConnected: isConnectedPoshmark, isInstalled } =
        usePoshmarkIsConnect();

      return (
        <>
          {!isInstalled ? (
            <InstallExtension />
          ) : !isConnectedPoshmark || !props?.data ? (
            <CardContent />
          ) : (
            <MarketplaceConnectedPlaceholder />
          )}
        </>
      );
    },
    MarketplaceButtons: (props) => {
      /**
       * @type {PoshmarkExtension}
       */
      const poshmark = useService(PoshmarkExtension);
      const {
        isConnected: isConnectedPoshmark,
        handleSignIn,
        isInstalled,
      } = usePoshmarkIsConnect();

      const onDisconnect = () => {
        poshmark.logOut();
      };

      return (
        <ButtonsContainer
          data={isConnectedPoshmark && props?.data}
          handleSignIn={handleSignIn}
          handleDisconnect={onDisconnect}
          modalText={getDisconnectMessage(MARKETPLACES_NAMES.poshmark)}
          isExtension={!isInstalled}
        />
      );
    },
  },
  {
    name: "Etsy",
    Logo: EtsyLogo,
    GoogleLogo: GoogleLogoWhite,
    backendKey: "etsyAccount",
    isExtension: true,
    CardContent: ({ data }) => {
      const { isConnected: isConnectedEtsy, isInstalled } = useEtsyIsConnect();

      return (
        <>
          {!isInstalled ? (
            <InstallExtension />
          ) : !isConnectedEtsy || !data ? (
            <div>{phrases.comingSoon}</div>
          ) : (
            <MarketplaceConnectedPlaceholder />
          )}
        </>
      );
    },
    MarketplaceButtons: (props) => {
      const { handleUpdateMarketplace, data } = props;

      const storage = useService(StorageService);

      const { isConnected: isConnectedEtsy, isInstalled } = useEtsyIsConnect();
      const marketplaceService = useService(MarketPlaceService);

      const [etsyConnectionInfo, handleOpenEtsyWindow] =
        useOpenWindow(KEY_ETSY_CONNECT);

      useEffect(() => {
        if (etsyConnectionInfo?.state === EBAY_PARAMS.STATE) return;
        if (etsyConnectionInfo?.code) {
          marketplaceService
            .connectEtsyAccount(`${etsyConnectionInfo.code}`)
            .then(() => handleUpdateMarketplace())
            .catch((e) => Bugsnag.notify(e))
            .finally(() => storage.set(KEY_ETSY_CONNECT, {}));
        }
      }, [
        etsyConnectionInfo,
        storage,
        marketplaceService,
        handleUpdateMarketplace,
      ]);

      const handleSignIn = useCallback(() => {
        marketplaceService
          .getEtsyAccount()
          .then(({ data: { authUrl } }) => authUrl)
          .then(handleOpenEtsyWindow)
          .catch((e) => Bugsnag.notify(e));
      }, [marketplaceService, handleOpenEtsyWindow]);

      const handleDisconnect = useCallback(() => {
        marketplaceService
          .disconnectEtsyAccount()
          .then(() => handleUpdateMarketplace())
          .catch((e) => Bugsnag.notify(e));
      }, [marketplaceService, handleUpdateMarketplace]);

      // return (
      //   <ButtonsContainer
      //     {...props}
      //     data={isConnectedEtsy && data}
      //     handleSignIn={handleSignIn}
      //     handleDisconnect={handleDisconnect}
      //     modalText={getDisconnectMessage(MARKETPLACES_NAMES.etsy)}
      //     isExtension={!isInstalled}
      //   />
      // );

      return <CustomButton disabled={true} className="filled-primary w-100 mb-2 mt-2">{phrases.comingSoon}</CustomButton>;
    },
  },
  {
    name: "Mercari",
    Logo: MercariLogo,
    backendKey: "mercariAccountDto", // <-- this is important
    GoogleLogo: GoogleLogoWhite,
    isExtension: true,
    CardContent: (props) => {
      const { isConnected: isConnectedMercari, isInstalled } =
        useMercariIsConnect();

      return (
        <>
          {!isInstalled ? (
            <InstallExtension />
          ) : !isConnectedMercari || !props?.data ? (
            <CardContent />
          ) : (
            <MarketplaceConnectedPlaceholder />
          )}
        </>
      );
    },
    MarketplaceButtons: (props) => {
      /**
       * @type {MercariExtension}
       */
      const mercari = useService(MercariExtension);

      const {
        isConnected: isConnectedMercari,
        handleSignIn,
        isInstalled,
      } = useMercariIsConnect();

      const onDisconnect = () => {
        mercari.logOut();
      };

      return (
        <ButtonsContainer
          data={isConnectedMercari && props?.data}
          handleSignIn={handleSignIn}
          handleDisconnect={onDisconnect}
          modalText={getDisconnectMessage(MARKETPLACES_NAMES.mercari)}
          isExtension={!isInstalled}
        />
      );
    },
  },
];
