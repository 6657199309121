import React, { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import Bugsnag from "@bugsnag/js";

import InfiniteScrollWrapper from "../../../base/components/InfiniteScrollWrapper";
import { useLoading } from "../../../base/hooks/useLoading";
import { useLocationQuery } from "../../../base/hooks/useQueryString";
import { useService } from "../../../base/hooks/useService";
import ShippingService from "../../../services/ShippingService";
import ShippingCard from "../components/shippingCard";
import ShippingHeader from "../components/shippingHeader";
import { useShippingList } from "../helpers/useShippingList";
import { useChangeShippingStatus } from "../helpers/useChangeShippingStatus";
import { SHIPPING_TASK_STATUSES } from "../constants";
import styles from "./index.module.scss";
import { changeSidebarShippingCounter } from "../../../store/actions";
import PickingFilters from "../components/PickingFilters";
import Placeholder from "../../inventory/components/Placeholder";
import emptyShippingIcon from "../images/shipping-empty.png";
import WhiteBox from "../../drafts/components/WhiteBox";
import SortDefaultIcon from "../../../assets/images/sort-default.svg";
import SortAscIcon from "../../../assets/images/sort-asc.svg";
import SortDescIcon from "../../../assets/images/sort-desc.svg";

const Shipping = () => {
  const dispatch = useDispatch();
  const [storedData, setStoredData] = useState([]);
  const [toggleSort, setToggleSort] = useState(null);
  const [sortIconImage, setSortIconImage] = useState(SortDefaultIcon);

  const [, { registerPromise }] = useLoading();
  /**
   * @type {ShippingService}
   */
  const shippingService = useService(ShippingService);

  const {
    params: { marketplaceType },
  } = useLocationQuery();

  const { data, hasMore, handleFetch, handleRefresh, totalCount, isLoading } =
    useShippingList({
      status: SHIPPING_TASK_STATUSES.SHIPPING,
    });

  const handleChangeStatus = useCallback(
    (event, id, shippingCost) => {
      event.preventDefault();
      registerPromise(
        shippingService.changeShippingStatusById(id, {
          status: SHIPPING_TASK_STATUSES.SHIPPED,
          shippingCost,
        })
      )
        .then(() => handleRefresh())
        .catch((e) => Bugsnag.notify(e));
    },
    [registerPromise, shippingService, handleRefresh]
  );

  useEffect(() => {
    dispatch(changeSidebarShippingCounter(totalCount));
  }, [dispatch, totalCount]);

  useEffect(() => {
    handleRefresh();
  }, [marketplaceType]);

  useEffect(() => {
    setStoredData(data);

    if (toggleSort !== null) {
      sortData();
    }
  }, [data]);

  useEffect(() => {
    if (storedData.length !== 0) {
      sortData();
    }
  }, [storedData.length, toggleSort]);

  const sortData = () => {
    const data = storedData.slice();

    const itemsWithoutSku = data.filter(
      (item) => Object.keys(item).indexOf("sku") === -1 || !item.sku
    );

    const itemsWithSku = data.filter(
      (item) =>
        Object.keys(item).indexOf("sku") !== -1 &&
        item.sku !== null &&
        item.sku !== undefined
    );

    if (toggleSort === null) return;

    const sortIcon = toggleSort === 1 ? SortDescIcon : SortAscIcon;

    if (itemsWithSku.length > 0) {
      itemsWithSku.sort((a, b) => {
        if (a.sku.toLowerCase() < b.sku.toLowerCase()) return toggleSort;

        if (a.sku.toLowerCase() > b.sku.toLowerCase()) return -1 * toggleSort;

        return 0;
      });
    }

    const sortedData = itemsWithSku.concat(itemsWithoutSku);

    setStoredData(sortedData);
    setSortIconImage(sortIcon);
  };

  const onSortData = () => {
    const sortType = toggleSort || 1;
    setToggleSort(-1 * sortType);
  };

  return (
    <>
      <div>
        {!!data?.length && (
          <ShippingHeader sortData={onSortData} sortIconImage={sortIconImage} />
        )}
        <PickingFilters sort={toggleSort} setSort={setToggleSort} />
        <InfiniteScrollWrapper
          hasMore={hasMore}
          fetchData={handleFetch}
          hasInfiniteLoader={!!data?.length}
        >
          <div className={styles.shippingList}>
            {isLoading ? (
              <WhiteBox>
                <p>Loading...</p>
              </WhiteBox>
            ) : data?.length ? (
              storedData.map((shipping) => (
                <ShippingCard
                  onCheckboxClick={handleChangeStatus}
                  onRefresh={handleRefresh}
                  {...shipping}
                  key={shipping.id}
                />
              ))
            ) : (
              <Placeholder
                text={"Currently no shipping tasks."}
                imgSrc={emptyShippingIcon}
              />
            )}
          </div>
        </InfiniteScrollWrapper>
      </div>
    </>
  );
};

export default Shipping;
