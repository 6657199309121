/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState, useRef } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import { Formik } from "formik";
import { Card, CardBody, Col, Row } from "reactstrap";

import { useService } from "../../../../../../base/hooks/useService";

import ConnectButton from "../../../../components/ConnectButton/ConnectButton";
import CustomButton from "../../../../../../base/components/CustomButton";
import MarketPlaceService from "../../../../../../services/MarketplaceService";
import DraftEbayFormInner from "../../../../components/DraftEbayFormInner";
import FormPartials from "../../../../classes/FormPartials";
import { FORM_TYPES } from "../../../../const/form";
import { useInitialFormValues } from "../../../../helpers/mapFormValues";
import { useHanleSaveDraftErrors } from "../../../../hooks/useSaveSubmit";
import CreationDate from "../../../../components/CreationDate";
import { DRAFTS_GROUP_LINKS } from "../../../../config";
import { useDeleteDraft } from "../../../../hooks/useDeleteDraft";
import DraftHeader from "../../../../components/DraftHeader";
import {
  BRAND_ERROR,
  DESCRIPTION_ERROR,
  LB_ERROR,
  OZ_ERROR,
  PRISE_ERROR,
  TITLE_ERROR,
} from "../../../../../../base/constants/forms";
import { useListingUrl } from "../../../../components/DraftEbayFormInner/partials/ListingUrl/hooks/useListingUrl";
import {
  EBAY_DRAFT_HIDDEN_VALIDATION,
  MARKETPLACES_NAMES_REQUEST,
} from "../../../../constants";
import { useLoading } from "../../../../../../base/hooks/useLoading";
import { productColors } from "../../../../../../base/constants/draft";
import { useCustomModal } from "../../../../hooks/useCustomModal";
import { generateFormModelWithBool } from "../../../../helpers/generateFormModelWithBool";
import {
  INVENTORY_UPDATE_SUCCESS,
  PRODUCT_LISTED_SUCCESS,
} from "../../../../../../base/constants/messages";
import {
  EDIT_INVENTORY_LINKS,
  INVENTORY_GROUP_LINKS,
} from "../../../../../inventory/config";
import ToasterService from "../../../../../../services/ToastService";
import { useProductStatusLabel } from "../../../../hooks/useProductStatusLabel";
import { useEbayIsConnect } from "../../../../hooks/useEbayIsConnect";
import { useDelistAction } from "../../../../hooks/useProductStatus";
import { getCountOfActiveItems } from "../../../../helpers/getTableFieldValue";
import "../../../../index.scss";
import EbayService from "../../../../../../services/EbayService";
import { usePathIncludes } from "../../../../hooks/usePathIncludes";
import { CREATE_DRAFT_LINKS } from "../../../CreateDraft/config";
import { EDIT_DRAFT_LINKS } from "../../config";
import { PRODUCT_TYPE } from "../../../../../../base/constants/product";
import EbayLogo from "../../../../../../base/components/EbayLogo";
import {
  getTouchedObj,
  scrollToErrors,
} from "../../../../hooks/useFormikScrollToErrors";
import ConnectPlaceholder from "../../../../components/ConnectPlaceholder";
import InstallExtensionPlaceholder from "../../../../components/InstallExtensionPlaceholder";
import { useExtensionInstall } from "../../../../hooks/Poshmark/useExtensionInstall";
import { useDraftConnectionBadget } from "../../../../hooks/useDraftConnectionBadget";
import ConnectedStatus from "../../../../components/ConnectedStatus/ConnectedStatus";
import { useFormatEbayDomesticShippingServices } from "../../../../hooks/useFormatEbayDomesticShippingServices";

import DraftUtils from "../../../../utils/DraftUtils";
import ProgressBar from "../../../../../sold/components/ProgressBar";
import Bugsnag from "@bugsnag/js";
import { phrases } from "../../../../../../store/phrases";
import { useGlobalLoading } from "../../../../../../base/contexts/LoadingContext";
import { MarketplaceFormMonitor } from "../../../../components/MarketplaceFormMonitor";

const isActivityDisabled = false;

const usePostEbay = (onSaveAndCreateNew) => {
  const history = useHistory();
  /**
   * @type {EbayService}
   */
  const ebay = useService(EbayService);
  const { reloadPage } = usePathIncludes(INVENTORY_GROUP_LINKS.BASE);
  /**
   * @type {ToasterService}
   */
  const toaster = useService(ToasterService);
  const { filterDomesticServices } = useFormatEbayDomesticShippingServices();

  const { setLoading: setLoadingWithMessage } = useGlobalLoading();

  const handlePost = useCallback(
    async (values, showProgressBar) => {
      if (values?.productEbaySpecificFields?.color) {
        const colorValue =
          productColors.find(
            (color) => color.id === values?.productEbaySpecificFields?.color
          )?.label || values?.productEbaySpecificFields?.color;
        values = {
          ...values,
          productEbaySpecificFields: {
            ...values?.productEbaySpecificFields,
            color: colorValue,
          },
        };
      }

      if (values?.productEbaySpecificFields?.shippingService) {
        if (
          parseInt(values?.productEbaySpecificFields?.shippingType, 10) === 1 ||
          parseInt(values?.productEbaySpecificFields?.shippingType, 10) === 2
        ) {
          const services = await new EbayService().getShippingData();
          const domesticServices = filterDomesticServices(services);

          const service = domesticServices.find(
            (service) =>
              service.shippingServiceId ===
              values?.productEbaySpecificFields?.shippingService
          );

          if (service) {
            const { shippingService } = service;

            values = {
              ...values,
              productEbaySpecificFields: {
                ...values.productEbaySpecificFields,
                shippingService,
              },
            };
          }
        }
      }

      let generatedDraftId;
      return onSaveAndCreateNew(values, null, "")
        .then(({ data: { id: draftId } }) => {
          Bugsnag.leaveBreadcrumb(`Ebay - ${phrases.postingToEbay}`, {
            draftId,
          });
          showProgressBar(phrases.postingToEbay);
          setLoadingWithMessage(true, phrases.postingToEbay);

          generatedDraftId = draftId;
          return ebay.postProduct(draftId);
        })
        .then(() => {
          Bugsnag.leaveBreadcrumb("Ebay - Navigating to listing");
          const draftId = generatedDraftId;
          const url = EDIT_INVENTORY_LINKS.EBAY.replace(":draftId", draftId);

          toaster.success(PRODUCT_LISTED_SUCCESS);

          const isCreateEbay = history.location.pathname.includes(
            CREATE_DRAFT_LINKS.EBAY
          );

          if (
            window.location.href.includes(`drafts/${draftId}`) ||
            window.location.href.includes(url) ||
            isCreateEbay
          ) {
            history.replace(url);
            reloadPage();
          }
        })
        .catch((baseError) => {
          const draftId = generatedDraftId;
          const { error } = baseError;
          Bugsnag.notify(baseError, (event) => {
            event.addMetadata("ebayHandlePostError", {
              values,
            });
          });

          const isCreateEbay = history.location.pathname.includes(
            CREATE_DRAFT_LINKS.EBAY
          );
          if (isCreateEbay) {
            history.replace(EDIT_DRAFT_LINKS.EBAY.replace(":draftId", draftId));
          }
          if (values.status === PRODUCT_TYPE.ACTIVE) {
            history.replace(
              EDIT_INVENTORY_LINKS.EBAY.replace(":draftId", draftId)
            );
          } else {
            history.replace(EDIT_DRAFT_LINKS.EBAY.replace(":draftId", draftId));
          }

          toaster.error(error.message);

          return Promise.reject();
        });
    },

    [
      onSaveAndCreateNew,
      filterDomesticServices,
      ebay,
      history,
      reloadPage,
      toaster,
    ]
  );

  return handlePost;
};
const EditDraftEbay = ({
  updateFullForm,
  fullFormData,
  onSave,
  onSaveAndCreateNew,
  onHandleDelete,
  fetchedData,
  toggleStatus,
  isDelistBtnDisabled,
  isDisableCreateDraft,
  isDirty,
  setFormLoading = () => {},
}) => {
  const {
    params: { draftId },
  } = useRouteMatch();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [categoriesLoading, setCategoriesLoading] = useState(false);
  const [processMessage, setProcessMessage] = useState("");

  const formRef = useRef();
  const [saving, { registerPromise }] = useLoading();

  const marketplaceService = useService(MarketPlaceService);

  /**
   * @type {ToasterService}
   */
  const toastr = useService(ToasterService);

  const [marketplaceData, updateMarketplaceData] = useState(null);
  const onCatchError = useHanleSaveDraftErrors();
  const { isInstalled } = useExtensionInstall();

  const { filterDomesticServices } = useFormatEbayDomesticShippingServices();
  const { setLoading: setLoadingWithMessage } = useGlobalLoading();

  const touched = {
    title: true,
    description: true,
    brand: true,
    color: true,
    weightLb: true,
    weightOz: true,
    listingPrice: true,
  };

  const baseErrors = {
    title: TITLE_ERROR,
    description: DESCRIPTION_ERROR,
    brand: BRAND_ERROR,
    weightLb: LB_ERROR,
    weightOz: OZ_ERROR,
    listingPrice: PRISE_ERROR,
  };

  const {
    view: popupView,
    onDelete,
    deleting,
  } = useDeleteDraft(() => {
    history.push(DRAFTS_GROUP_LINKS.BASE);
  });

  const handleDelete = () => {
    onHandleDelete();
    onDelete(draftId);
  };

  const handleUpdateMarketplace = useCallback(() => {
    marketplaceService
      .getMarketplace()
      .then(({ data }) => updateMarketplaceData({ data }))
      .catch((e) => Bugsnag.notify(e));
  }, [marketplaceService]);

  useEffect(() => {
    handleUpdateMarketplace();
  }, [handleUpdateMarketplace]);

  const formPartials = new FormPartials(FORM_TYPES.EBAY);

  const initialValues = useInitialFormValues(
    fullFormData,
    formPartials.initialValues,
    formPartials.mapFields,
    formPartials.formKey
  );

  const post = usePostEbay(onSaveAndCreateNew);

  const showProgressBar = (message) => {
    setLoading(true);
    setProcessMessage(message);
  };

  const hideProgressBar = () => {
    setLoading(false);
    setProcessMessage("");
  };

  const handlePost = (values, helpers, touchedValues) => {
    const { setErrors, setTouched } = helpers;
    Bugsnag.leaveBreadcrumb(`Ebay - ${phrases.preparingData}`, values);
    showProgressBar(phrases.preparingData);
    setLoadingWithMessage(true, phrases.preparingData);

    const compiledValues = {
      ...values,
      productEbaySpecificFields: {
        ...values.productEbaySpecificFields,
        ...touchedValues,
      },
    };

    return registerPromise(post(compiledValues, showProgressBar))
      .catch((err) =>
        onCatchError(err, () => {
          Bugsnag.notify(err);
          setTouched(touched, false);
          setErrors(baseErrors);
        })
      )
      .finally(() => {
        hideProgressBar();
        setLoadingWithMessage(false);
      });
  };

  const handleSave = useCallback(
    async (values, { setErrors, setTouched }) => {
      if (values?.productEbaySpecificFields?.color) {
        const colorValue =
          productColors.find(
            (color) => color.id === values?.productEbaySpecificFields?.color
          )?.label || values?.productEbaySpecificFields?.color;
        values = {
          ...values,
          productEbaySpecificFields: {
            ...values?.productEbaySpecificFields,
            color: colorValue,
          },
        };
      }

      if (values?.productEbaySpecificFields?.shippingService) {
        if (
          parseInt(values?.productEbaySpecificFields?.shippingType, 10) === 1 ||
          parseInt(values?.productEbaySpecificFields?.shippingType, 10) === 2
        ) {
          const services = await new EbayService().getShippingData();
          const domesticServices = filterDomesticServices(services);

          const service = domesticServices.find(
            (service) =>
              service.shippingServiceId ===
              values?.productEbaySpecificFields?.shippingService
          );

          if (service) {
            const { shippingService } = service;

            values = {
              ...values,
              productEbaySpecificFields: {
                ...values.productEbaySpecificFields,
                shippingService,
              },
            };
          }
        }
      }

      showProgressBar("Saving your changes");

      return registerPromise(onSave(values))
        .catch((err) =>
          onCatchError(err, () => {
            Bugsnag.notify(err, (event) => {
              event.addMetadata("ebayHandleSaveError", {
                values,
              });
            });
            setTouched(touched, false);
            setErrors(baseErrors);
          })
        )
        .finally(hideProgressBar);
    },

    [onSave, registerPromise]
  );

  const handleSaveNewDraftSubmit = useCallback(
    (values, { resetForm, setErrors, setTouched }) => {
      showProgressBar("Saving your changes");
      return registerPromise(onSaveAndCreateNew(values))
        .then(() => {
          resetForm();
        })
        .catch((err) =>
          onCatchError(err, () => {
            Bugsnag.notify(err);
            setTouched(touched, false);
            setErrors(baseErrors);
          })
        )
        .finally(hideProgressBar);
    },

    [onSaveAndCreateNew, registerPromise]
  );

  const { isPosted, link } = useListingUrl({ fetchedData });
  const { delist } = useDelistAction({
    onDelist: onHandleDelete,
    onToggleStatus: toggleStatus,
  });

  const handleDelistAction = () => {
    const countOfActiveListing = getCountOfActiveItems(fetchedData);

    showProgressBar("Delisting");
    setLoadingWithMessage(true, "Delisting item");
    registerPromise(
      delist({
        draftId,
        marketplace: MARKETPLACES_NAMES_REQUEST.ebay,
        countOfActiveListing,
      })
    ).finally(() => {
      hideProgressBar();
      setLoadingWithMessage(false);
    });
  };

  const { modal: modalDelist, onShowModal: onShowModalDelist } = useCustomModal(
    {
      title: "Delist item",
      message: "Do you want to delist the item from eBay?",
      onAccept: handleDelistAction,
    }
  );

  const handleUpdateAction = () => {
    showProgressBar(
      "Updating your changes. You may leave this page while we process your request."
    );
    setLoadingWithMessage(true, "Updating your changes");
    const productEbaySpecificFields = {
      ...fullFormData.productEbaySpecificFields,
      ...formRef.current.values,
    };

    fullFormData.productEbaySpecificFields = productEbaySpecificFields;

    if (fullFormData?.productEbaySpecificFields) {
      fullFormData.productEbaySpecificFields.isUpdated = true;
    }

    return registerPromise(
      onSave(fullFormData, "", null)
        .then(() => {
          history.replace(
            EDIT_INVENTORY_LINKS.EBAY.replace(":draftId", draftId)
          );

          const model = generateFormModelWithBool(formRef.current?.values);

          formRef.current?.setTouched(model, false);
          formRef.current?.setErrors(model);

          toastr.success(INVENTORY_UPDATE_SUCCESS);
        })
        .catch((e) =>
          Bugsnag.notify(e, (event) => {
            event.addMetadata("ebayHandleupdateActionError", {
              fullFormData,
            });
          })
        )
    ).finally(() => {
      hideProgressBar();
      setLoadingWithMessage(false);
    });
  };

  const { modal: modalUpdate, onShowModal: onShowModalUpdate } = useCustomModal(
    {
      title: "Update item",
      message: "Are you sure you want to apply changes on your eBay listing?",
      onAccept: handleUpdateAction,
    }
  );

  const [getProductStatusLabel] = useProductStatusLabel();
  const { isConnected } = useEbayIsConnect();
  const { showConnectBadget } = useDraftConnectionBadget();

  useEffect(() => {
    setFormLoading(saving || categoriesLoading);
  }, [saving, categoriesLoading]);

  useEffect(() => {
    document.getElementById("ebay-form").reset();
  }, []);

  return (
    <Card className="mt-3">
      {popupView}
      {modalDelist}
      {modalUpdate}
      <CardBody>
        {loading && <ProgressBar isActive processMessage={processMessage} />}
        <Formik
          initialValues={initialValues}
          validationSchema={formPartials.validations}
          onSubmit={(touchedValues, helpers) => {
            return handlePost(fullFormData, helpers, touchedValues);
          }}
          innerRef={formRef}
        >
          {(helpers) => {
            const {
              handleSubmit,
              setFieldValue,
              values,
              isValid,
              touched: touchedFields,
            } = helpers;

            const isDisablePost =
              !marketplaceData?.data.ebayAccount?.expirationDate ||
              saving ||
              values?.title?.length >
                EBAY_DRAFT_HIDDEN_VALIDATION.title.maxValue;

            return (
              <form
                className="form-horizontal p-2"
                onSubmit={handleSubmit}
                id="ebay-form"
              >
                <MarketplaceFormMonitor
                  item={fullFormData}
                  formKey="productEbaySpecificFields"
                  localForm={values}
                  updateFullForm={updateFullForm}
                  touchedFields={touchedFields}
                />

                <DraftHeader
                  showSettings
                  title="Ebay form"
                  status={getProductStatusLabel(
                    fetchedData?.productEbaySpecificFields?.status
                  )}
                  marketplace={MARKETPLACES_NAMES_REQUEST.ebay}
                  fetchedData={fetchedData}
                  toggleStatus={toggleStatus}
                  marketplaceData={marketplaceData}
                  marketplaceAccountKey="ebayAccount"
                  isConnected={isConnected}
                >
                  {isPosted && (
                    <>
                      <CustomButton
                        className="filled-sm w-80 mr-2"
                        onClick={() => window.open(link, "_blank")}
                        disabled={!!formRef?.current?.errors?.link || saving}
                      >
                        View item
                      </CustomButton>
                      <CustomButton
                        className="filled-sm mr-2"
                        onClick={
                          isValid
                            ? onShowModalUpdate
                            : () => {
                                helpers.validateForm().then((errors) => {
                                  helpers
                                    .setTouched(getTouchedObj(errors))
                                    .then(scrollToErrors);
                                });
                              }
                        }
                        disabled={
                          isActivityDisabled ||
                          !isDirty ||
                          saving ||
                          deleting ||
                          !isConnected ||
                          !marketplaceData?.data.ebayAccount
                        }
                      >
                        Revise
                      </CustomButton>
                    </>
                  )}
                  <ConnectButton
                    marketplaceData={marketplaceData}
                    handleUpdateMarketplace={handleUpdateMarketplace}
                  />
                </DraftHeader>

                {!isInstalled && showConnectBadget && (
                  <InstallExtensionPlaceholder marketPlace={"Ebay"} />
                )}
                {isInstalled &&
                  showConnectBadget &&
                  (!isConnected || !marketplaceData?.data.ebayAccount) && (
                    <ConnectPlaceholder>
                      {phrases.ebayNotConnectedDrafts}
                    </ConnectPlaceholder>
                  )}

                <DraftEbayFormInner
                  values={values}
                  postedInfo={{ isPosted, link }}
                  setFieldValue={setFieldValue}
                  marketplaceData={marketplaceData}
                  formRef={formRef}
                  suggestedFields={DraftUtils.extractSuggestedFields(
                    fullFormData
                  )}
                  isPosted={isPosted}
                  isLoading={setCategoriesLoading}
                />
                <div className={"mb-5"}>
                  <EbayLogo />
                </div>

                <Row>
                  <Col className="d-flex">
                    <CreationDate
                      text="Creation date:"
                      date={values.createdAt}
                      className="mr-60"
                    />
                    {!!draftId && (
                      <CreationDate
                        className="mr-60"
                        text="Last Updated:"
                        date={values.updatedAt}
                      />
                    )}
                    <ConnectedStatus
                      text="Status:"
                      className="mr-60"
                      isActive={
                        !!marketplaceData?.data.ebayAccount?.id && isConnected
                      }
                      isConnected={isConnected}
                      isExpired={marketplaceData?.data?.ebayAccount?.isExpired}
                    />
                    {marketplaceData?.data.ebayAccount?.expirationDate && (
                      <CreationDate
                        text="Date Expires:"
                        date={marketplaceData?.data.ebayAccount?.expirationDate}
                      />
                    )}
                  </Col>
                  <Col
                    ms="7"
                    className="d-flex justify-content-md-end align-items-center"
                  >
                    {!isPosted && (
                      <>
                        {!!draftId && (
                          <CustomButton
                            className="outline-danger w-80 mr-2"
                            onClick={handleDelete}
                            disabled={deleting || saving}
                          >
                            Delete
                          </CustomButton>
                        )}
                        <CustomButton
                          className="filled-primary w-80 mr-2"
                          disabled={deleting || saving}
                          onClick={() => handleSave(fullFormData, helpers)}
                        >
                          Save
                        </CustomButton>
                        <CustomButton
                          disabled={deleting || saving || isDisableCreateDraft}
                          onClick={() =>
                            handleSaveNewDraftSubmit(fullFormData, helpers)
                          }
                          className="outline-primary mr-2 save-new-btn"
                        >
                          Save + New draft
                        </CustomButton>
                        <CustomButton
                          className="outline-primary w-80 mr-2"
                          disabled={isDisablePost || isActivityDisabled}
                          type={isValid ? "submit" : "button"}
                          onClick={
                            isValid
                              ? undefined
                              : () => {
                                  helpers.validateForm().then((errors) => {
                                    helpers
                                      .setTouched(getTouchedObj(errors))
                                      .then(scrollToErrors);
                                  });
                                }
                          }
                        >
                          Post
                        </CustomButton>
                      </>
                    )}

                    {isPosted && (
                      <>
                        <CustomButton
                          className="outline-danger w-80 mr-2"
                          onClick={onShowModalDelist}
                          disabled={deleting || saving || isDelistBtnDisabled}
                        >
                          Delist
                        </CustomButton>
                        <CustomButton
                          className="filled-primary w-80  mr-2"
                          onClick={() => window.open(link, "_blank")}
                          disabled={!!formRef?.current?.errors?.link || saving}
                        >
                          View item
                        </CustomButton>
                        <CustomButton
                          className="filled-primary w-80"
                          disabled={
                            isActivityDisabled ||
                            saving ||
                            deleting ||
                            !isDirty ||
                            !isConnected ||
                            !marketplaceData?.data.ebayAccount
                          }
                          onClick={
                            isValid
                              ? onShowModalUpdate
                              : () => {
                                  helpers.validateForm().then((errors) => {
                                    helpers
                                      .setTouched(getTouchedObj(errors))
                                      .then(scrollToErrors);
                                  });
                                }
                          }
                        >
                          Revise
                        </CustomButton>
                      </>
                    )}
                  </Col>
                </Row>
              </form>
            );
          }}
        </Formik>
      </CardBody>
    </Card>
  );
};

export default EditDraftEbay;
