import React, { useCallback, useEffect, useState } from "react";
import { Col, Row, Spinner } from "reactstrap";
import Bugsnag from "@bugsnag/js";

import TradingFloor from "../../../../components/TradingFloor";
import { MARKETPLACE_PLATFORMS } from "./MarketplacePlatforms";
import MarketPlaceService from "../../../../../../services/MarketplaceService";
import { useService } from "../../../../../../base/hooks/useService";
import { useLoading } from "../../../../../../base/hooks/useLoading";
import { CustomBanner } from "../../../../../../base/components/CustomBanner";
import { phrases } from "../../../../../../store/phrases";

const MarketPlaceConnection = () => {
  const marketplaceService = useService(MarketPlaceService);
  const [marketplaceData, updateMarketplaceData] = useState(null);
  const [, { registerPromise }] = useLoading();

  const handleUpdateMarketplace = useCallback(() => {
    registerPromise(marketplaceService.getMarketplace())
      .then(({ data }) => updateMarketplaceData(data))
      .catch((e) => Bugsnag.notify(e));
  }, [marketplaceService, registerPromise]);

  useEffect(() => {
    handleUpdateMarketplace();
  }, [handleUpdateMarketplace]);

  if (!marketplaceData) {
    return (
      <div className="d-flex justify-content-center mt-3">
        <Spinner
          style={{
            width: "48px",
            height: "48px",
            color: "rgba(47, 102, 92, 1)",
          }}
        />
      </div>
    );
  }

  return (
    <div className="mt-3">
      <CustomBanner
        text={phrases.connectionsRefresh}
      />
      <Row>
        {MARKETPLACE_PLATFORMS.map((platform, index) => (
          <Col md="6" xl="4" key={index}>
            <TradingFloor
              data={
                platform.backendKey && marketplaceData?.[platform.backendKey]
              }
              handleUpdateMarketplace={handleUpdateMarketplace}
              {...platform}
            />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default MarketPlaceConnection;
