import React, { useCallback, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import logoBurger from "../../../assets/images/navigation/icon-16-delete-grey.webp";
import logoLg from "../../../assets/images/sidebar/new-logo.svg";
import logoSm from "../../../assets/images/sidebar/logo-small.png";

import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../../store/actions";
import ProfileMenu from "../ProfileMenu";
import ProfileSetting from "../ProfileSetting";
import { useService } from "../../hooks/useService";
import StorageService from "../../../services/StorageService";
import { KEY_HEADER_MENU } from "../../constants/storage";
import { GlobalLoader } from "../GlobalLoader/GlobalLoader";
import { GlobalBanner } from "../GlobalBanner";

const Header = (props) => {
  const { changeSidebarType } = props;

  const isMobile = useMemo(() => {
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  }, []);

  /**
   * @type {StorageService}
   */
  const storage = useService(StorageService);

  const handleToggleSidebar = useCallback(() => {
    props.toggleLeftmenu(!props.leftMenu);

    if (props.leftSideBarType === "default") {
      changeSidebarType("condensed", isMobile);
      storage.set(KEY_HEADER_MENU, "condensed");
    } else if (props.leftSideBarType === "condensed") {
      changeSidebarType("default", isMobile);
      storage.set(KEY_HEADER_MENU, "default");
    }
  }, [props, isMobile, storage]);

  useEffect(() => {
    const value = storage.get(KEY_HEADER_MENU);

    if (!value) return;

    changeSidebarType(value, isMobile);
  }, [changeSidebarType, isMobile, storage]);

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex align-items-center">
            <div className="navbar-brand-box">
              <Link to="/app/dashboard" className="logo logo-light ">
                <span className="logo-sm text-left">
                  <img src={logoSm} alt="" height="34" />
                </span>

                <span className="logo-lg text-left">
                  <img src={logoLg} alt="" height="34px" />
                </span>
              </Link>
            </div>
            <button
              type="button"
              onClick={handleToggleSidebar}
              className="btn btn-sm px-4 font-size-16 header-item waves-effect"
              id="vertical-menu-btn"
            >
              <img src={logoBurger} alt="" height="16" />
            </button>
          </div>
          <GlobalBanner />
          <div className="d-flex align-items-center justify-self-end">
            <GlobalLoader />
            <ProfileMenu />
            <ProfileSetting />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
  loading: PropTypes.bool,
};

const mapStatetoProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout;
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(Header);
