import Bugsnag from "@bugsnag/js";

import Http from "./Http";
import {
  MARKETPLACE_EBAY_TYPE,
  MARKETPLACE_MERCARI_TYPE,
  MARKETPLACE_POSHMARK_TYPE,
} from "../pages/inventory/constants/importInventory";
import { splitByChunks } from "../base/helpers/chunks";
import { FORM_TYPES } from "../pages/drafts/const/form";

class ImportProductService extends Http {
  static $displayName = "ImportProduct";

  importProductTask(values) {
    return this.post("/import-product-tasks", values);
  }

  importProduct(values, marketplace) {
    if (marketplace === FORM_TYPES.EBAY || marketplace === FORM_TYPES.ETSY) {
      values = {
        listingId: values.listingId,
        orderId: values.orderId,
        status: values.status,
        shippingLabel: values.shippingLabel,
      };
    }
    return this.post(`/products/${marketplace}/import`, values);
  }

  getProductsImportedStatus({ marketplaceType, listingIds }) {
    const isExtensionMarketplaces = [
      MARKETPLACE_POSHMARK_TYPE,
      MARKETPLACE_MERCARI_TYPE,
      MARKETPLACE_EBAY_TYPE,
    ].includes(marketplaceType);

    if (!isExtensionMarketplaces) {
      return new Promise((resolve) => {
        resolve({ data: [], shouldSkip: true });
      });
    }

    const chunks = splitByChunks(listingIds, 50);
    const requests = chunks.map((listingIds) =>
      this.get(`/products/marketplaces/import`, {
        params: { marketplaceType, listingIds },
      })
        .then(({ data }) => data)
        .catch((e) => Bugsnag.notify(e))
    );

    return Promise.all(requests)
      .then((lists) => {
        return { data: lists.flat() };
      })
      .catch((e) => Bugsnag.notify(e));
  }
}

export default ImportProductService;
